<template>
  <t-body>
    <t-sidebar-menu v-if="!$fetchState.pending" :title="organization.name" :items="items" />
    <t-loading v-if="$fetchState.pending" />
    <nuxt-child v-else :organization="organization" @updated="updated" />
  </t-body>
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import TSidebarMenu from '~/components/TSidebarMenu';
import TLoading from '~/components/TLoading';

export default Vue.extend({
  components: { TSidebarMenu, TLoading },

  data() {
    return {
      organization: {},
    };
  },

  async fetch() {
    this.organization = (await new Organization().find(this.$route.params.id)).data;
  },

  computed: {
    items() {
      return [
        {
          primary: this.$t('pages.organization.details.heading'),
          secondary: this.$t('pages.organization.details.subheading'),
          icon: 'cog',
          routeName: this.$RouteEnum.ORGANIZATION.DETAILS,
        },
        {
          primary: this.$t('pages.organization.profiles.heading'),
          secondary: this.$t('pages.organization.profiles.subheading'),
          icon: ['far', 'folder'],
          routeName: this.$RouteEnum.ORGANIZATION.PROFILES,
        },
        {
          primary: this.$t('pages.organization.employees.heading'),
          secondary: this.$t('pages.organization.employees.subheading'),
          icon: 'users',
          routeName: this.$RouteEnum.ORGANIZATION.EMPLOYEES,
        },
        {
          primary: this.$t('pages.organization.invitations.heading'),
          secondary: this.$t('pages.organization.invitations.subheading'),
          icon: 'user-plus',
          routeName: this.$RouteEnum.ORGANIZATION.INVITATIONS,
        },
        {
          primary: this.$t('pages.organization.registration_codes.heading'),
          secondary: this.$t('pages.organization.registration_codes.subheading'),
          icon: 'key',
          routeName: this.$RouteEnum.ORGANIZATION.REGISTRATION_CODES,
        },
        {
          primary: this.$t('pages.organization.email_templates.heading'),
          secondary: this.$t('pages.organization.email_templates.subheading'),
          icon: 'envelope',
          routeName: this.$RouteEnum.ORGANIZATION.EMAIL_TEMPLATES,
        },
        {
          primary: this.$t('pages.organization.alerts.heading'),
          secondary: this.$t('pages.organization.alerts.subheading'),
          icon: ['far', 'bell'],
          routeName: this.$RouteEnum.ORGANIZATION.ALERTS,
        },
        {
          primary: this.$t('pages.organization.pings.heading'),
          secondary: this.$t('pages.organization.pings.subheading'),
          icon: 'comment-alt',
          routeName: this.$RouteEnum.ORGANIZATION.PINGS,
        },
      ];
    },
  },

  methods: {
    updated(organization) {
      this.organization = organization;
    },
  },
});
</script>
