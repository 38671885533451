<template>
  <organization-show />
</template>

<script>
import Vue from 'vue';
import OrganizationShow from '~/pages/shared/organizations/OrganizationShow.vue';

export default Vue.extend({
  components: { OrganizationShow },
});
</script>
