<template>
  <t-body>
    <t-sidebar-menu :title="$t('fields.organization')" :items="items" />
    <nuxt-child />
  </t-body>
</template>

<script>
import Vue from 'vue';
import TSidebarMenu from '~/components/TSidebarMenu';

export default Vue.extend({
  components: { TSidebarMenu },

  computed: {
    items() {
      return [
        {
          primary: this.$t('pages.organization.details.heading'),
          secondary: this.$t('pages.organization.details.subheading'),
          icon: 'cog',
          routeName: this.$RouteEnum.ORGANIZATION.DETAILS,
        },
        {
          primary: this.$t('pages.organization.employees.heading'),
          secondary: this.$t('pages.organization.employees.subheading'),
          icon: 'users',
          routeName: this.$RouteEnum.ORGANIZATION.EMPLOYEES,
        },
        {
          primary: this.$t('pages.organization.invitations.heading'),
          secondary: this.$t('pages.organization.invitations.subheading'),
          icon: 'user-plus',
          routeName: this.$RouteEnum.ORGANIZATION.INVITATIONS,
        },
      ];
    },
  },
});
</script>
